// src/components/MainTemplate.js
import React from 'react';
import ParticleBackground from './ParticleBackground';
import astronaut from '../assets/astronout.png';
import moon from '../assets/moon.png';

const MainTemplate = ({ children }) => {
  return (
    <div>
      <div className="App-header">
        <div className="Astronaut">
          <div className="background-animation">
            <div className="thought_body">
              <div className="thought">Houston, we have a problem !</div>
            </div>
            <img src={astronaut} alt="astronaut" />
          </div>
        </div>
        <img src={moon} className="Moon" alt="moon" />
      </div>
    <div className="form-container">
      <ParticleBackground />
      <div className="form-wrapper">
        {children}
      </div>
    </div>
    </div>
  );
};

export default MainTemplate;
