// src/components/FinalStep.js
import React from 'react';
import MainTemplate from './MainTemplate';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={120} thickness={5.5} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const FinalStep = () => {
  const [loadingProgress, setLoadingProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setTimeout(() => setLoading(false), 1000); // Slight delay before hiding the loader
          return 100;
        }
        return prevProgress + 0.5;
      });
    }, 50); // Adjust the speed of the loading here (50ms for smoothness)

    return () => clearInterval(interval);
  }, []);



  return (
    <MainTemplate>
      <h2>Solution</h2>
      <Box      
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      >
        {loading ? (
          <div>
            <Box
            display="flex" 
            justifyContent="center" 
            alignItems="center" >
              <Typography variant="h5">
              Our AI is calculating the solution, please Wait!
              </Typography>
              </Box>
              <Box
            display="flex" 
            justifyContent="center" 
            alignItems="center"
            p={2}
            >
          <CircularProgressWithLabel value={loadingProgress} />
          </Box>
        </div>
      ) : (
        <Fade in={!loading}>
          <Typography variant="h4">
          <p>Did you try turning it off and on again?</p>
          <div className="verified">
          <VerifiedIcon color="success" sx={{ fontSize: 90 }} />
          </div>
          </Typography>
        </Fade>
      )}
      </Box>
    </MainTemplate>
  );
};

export default FinalStep;
