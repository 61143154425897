// src/components/Step2.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainTemplate from './MainTemplate';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

const Step2 = () => {
  const [detail, setDetail] = useState('');
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/final');
  };

  return (
    <MainTemplate>
      <FormControl>
      <LinearProgress variant="determinate" value={10} />
        <h2 id="demo-radio-buttons-group-label">Can you provide more details?</h2>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="detail"
        >
          <FormControlLabel 
          value="slow" 
          control={<Radio />} 
          label="System is slow" 
          checked={detail === 'slow'}
          onChange={() => setDetail('slow')} />
          <Divider/>
          <FormControlLabel 
          value="not-responding"
          control={<Radio />}
          label="Applications not responding"
          checked={detail === 'not-responding'}
          onChange={() => setDetail('not-responding')} />
          <Divider/>
          <FormControlLabel 
          value="error-messages" 
          control={<Radio />} 
          label="I have some Error Messages"
          checked={detail === 'error-messages'}
          onChange={() => setDetail('error-messages')} />
        </RadioGroup>
        <Divider/><br/>
        <Button variant="contained" onClick={handleNext}>Next</Button>
      </FormControl>
    </MainTemplate>
  );
};

export default Step2;
