// src/components/Step1.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainTemplate from './MainTemplate';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Divider from '@mui/material/Divider';

const Step1 = () => {
  const [issue, setIssue] = useState('');
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/step2');
  };

  return (
    <MainTemplate>
      <FormControl>
      <LinearProgress variant="determinate" value={0} />
        <h2 id="demo-radio-buttons-group-label">What seems to be the problem?</h2>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="detail"
        >
          <FormControlLabel 
          value="network" 
          control={<Radio />} 
          label="Network/Wifi/Internet is not Working" 
          checked={issue === 'network'}
          onChange={() => setIssue('network')} />
          <Divider/>
          <FormControlLabel 
          value="hardware"
          control={<Radio />}
          label="My Computer/Printer/Hardware is not Working"
          checked={issue === 'hardware'}
          onChange={() => setIssue('hardware')} />
          <Divider/>
          <FormControlLabel 
          value="software" 
          control={<Radio />} 
          label="The App/Program/Software is not Working"
          checked={issue === 'software'}
          onChange={() => setIssue('software')} />
        </RadioGroup>
        <Divider/><br/>
        <Button variant="contained" onClick={handleNext}>Next</Button>
      </FormControl>
    </MainTemplate>
  );
};

export default Step1;
