// src/App.js
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import FinalStep from './components/FinalStep';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-SZREL8GBGH');

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Step1 />} />
          <Route path="/step2" element={<Step2 />} />
          <Route path="/final" element={<FinalStep />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
